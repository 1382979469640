// aud
// far
// bec
// reg

$m-text: rgb(240, 240, 240);
$aud-green: #9bbd48;
$aud-green-100: #8ba840;
$aud-green-50: #9cbd48cc;
$aud-gradient: -webkit-linear-gradient(40deg, $aud-green, $aud-green-50);
$border-radius-5: 5px;
$margin-bottom-15: 15px;
$bec-yellow: #ce9b00;
$bec-yellow-100: #daaa1b;
$bec-yellow-50: #ffc415;
$bec-gradient: -webkit-linear-gradient(40deg, $bec-yellow, $bec-yellow-50);
$far-blue: #34afef;
$far-blue-100: #1691d3;
$far-blue-50: #51b5eb;
$far-gradient: -webkit-linear-gradient(40deg, $far-blue, $far-blue-50);
$reg-red: #cf3539;
$reg-red-100: #c42026;
$reg-red-50: #f04a50;
$reg-gradient: -webkit-linear-gradient(40deg, $reg-red, $reg-red-50);

$study-purple: #5862E7;
$study-purple-100: #5862E7;
$study-purple-50: #9184E3;
$purple-gradient: -webkit-linear-gradient(40deg, $study-purple, $study-purple-50);

$mblue:#0044ba;
$mlblue:#669eff;
$blue:#2812af;
$peach:#f7898d;
$blue-gradient: -webkit-linear-gradient(40deg, $peach, $blue);

.miles-blue-text{
  color:$mblue !important;
}
.miles-blue{
  background:$mblue !important;
}
.miles-lblue-text{
  color:$mlblue !important;
}
.miles-lblue{
  background:$mlblue !important;
}

.aud-txt {
  color: $aud-green;
}

.aud-grd {
  background: $aud-gradient;
  color: #555;
  border-radius: $border-radius-5;
  margin-bottom: $margin-bottom-15;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
}

.bec-grd {
  background: $bec-gradient !important;
  color: #555;
  border-radius: $border-radius-5;
  margin-bottom: $margin-bottom-15;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
}

.far-grd {
  background: $far-gradient !important;
  color: #555;
  border-radius: $border-radius-5;
  margin-bottom: $margin-bottom-15;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
}

.reg-grd {
  background: $reg-gradient !important;
  color: #555;
  border-radius: $border-radius-5;
  margin-bottom: $margin-bottom-15;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
}

.web-grd {
  background: $purple-gradient !important;
  color: #555;
  border-radius: $border-radius-5;
  margin-bottom: $margin-bottom-15;
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
}
// .web-grd{
//     background: $blue-gradient !important;
//     color: #555;
//     border-radius: $border-radius-5;
//     margin-bottom: $margin-bottom-15;
//     font-size: 0.8rem;
//     font-weight: 300;
//     text-transform: uppercase;
//   }
.aud-btn {
  background: $aud-gradient;
  color: #fff;
  border: 1px solid $aud-green-100;
  box-shadow: 1px 1px 5px #000;

}

.coupon_box {
  background-color: #fdfdfd;
  padding: 15px;
  margin-top: 15px;
  border: 1px dashed #eaeaea;
  border-radius: 5px;
}
